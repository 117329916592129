<template>
  <div class="ml-8 mr-6">
    <v-row class="mt-2 mb-1">
      <v-col
        class="ma-0 pa-0"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="(fil, i) in filters"
        :key="i"
      >
        <MyFilter
          :filter="fil"
          @option="filterChange"
          ref="filter"
          :submitFilterValues="submitFilterValues"
          :defaultValues="defaultValues"
        ></MyFilter>
      </v-col>
    </v-row>
    <div class="text-right">
      <button
        class="btn iq-btn px-3 px-sm-4 py-2 poppins-ls"
        @click="applyFilters"
      >
        Search
      </button>
      <button
        class="ml-2 btn iq-btn px-3 px-sm-4 py-2 poppins-ls"
        @click="resetFilters"
      >
        Clear
      </button>
    </div>
  </div>
</template>

<script>
import MyFilter from "@/own/components/filter/MyFilter.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "Filters",
  components: { MyFilter },
  props: {
    submitFilterValues: {
      required: true,
      type: Function,
    },
    resetFilterValues: {
      required: true,
      type: Function,
    },
    setTableFiltersValues: {
      required: true,
      type: Function,
    },
    filters: {
      required: true,
      type: Array,
    },
    defaultValues: {
      required: false,
      default: false,
    },
  },
  data: () => ({
    queryObj: {},
    men: [],
  }),
  methods: {
    applyFilters() {
      setTimeout(
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.filter[0].$el.focus();
            this.setTableFiltersValues(this.men);
            this.submitFilterValues();
          }, 10);
        })
      );
    },
    resetFilters() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$refs.filter.forEach((filter) => {
        filter.clearData();
      });
      this.queryObj = {};
      this.men = [];
      this.resetFilterValues();
    },
    filterChange(val) {
      this.men = [];

      Object.entries(val).forEach((data) => {
        if (data[1] != null && data[0] != null) {
          this.queryObj[data[0]] = data[1];
        }
        if (data[0] == null || data[1] == null || data[1].length == 0) {
          delete this.queryObj[data[0]];
        }
      });

      Object.keys(this.queryObj).forEach((l) => {
        this.men.push({ name: l, value: this.queryObj[l] });
      });
      this.setTableFiltersValues(this.men);
    },
  },
  computed: {
    isClient: function () {
      return this.$store.getters.currentUser.data.is_client;
    },
  },
};
</script>
